const CLOUD_CREDITS_PREFIX = "/cloud-credits/";

function amountFromCloudCredits(cloudCreditsString: string): number {
  if (!cloudCreditsString.startsWith(CLOUD_CREDITS_PREFIX)) return 0;
  const segments = cloudCreditsString.split("/");
  if (segments.length < 3) return 0;
  const amount = parseInt(segments[2]);
  return isNaN(amount) || amount < 1 ? 0 : amount;
}

function cartIdFromCloudCredits(cloudCreditsString: string): string {
  if (!cloudCreditsString.startsWith(CLOUD_CREDITS_PREFIX)) return "";
  const segments = cloudCreditsString.split("/");
  if (segments.length < 4) return "";
  return segments[3].trim();
}

function versionFromCloudCredits(cloudCreditsString: string): string {
  const amount = amountFromCloudCredits(cloudCreditsString);
  return versionFromCloudCreditAmount(amount);
}

function versionFromCloudCreditAmount(amount: number): string {
  if (amount < 1) return "";
  return `${CLOUD_CREDITS_PREFIX}${amount}`;
}

export {
  CLOUD_CREDITS_PREFIX,
  amountFromCloudCredits,
  cartIdFromCloudCredits,
  versionFromCloudCredits,
  versionFromCloudCreditAmount
};
