import React from "react";
import Form from "react-bootstrap/Form";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import type { UseFormReturn } from "react-hook-form";
import clsx from "clsx";

import { amountFromCloudCredits } from "../../utils/cloudCredits";
import { ValidationError, formControlCssClass } from "../../utils/errors";
import { amountFromOffer } from "../../utils/offers";
import { CenteredSpinner, Spinner } from "../../utils/spinner";

import { OrderSummaryTable } from "./OrderSummary";

import {
  setCalculatedPrice,
  setExistingProduct,
  useCartStateDispatch
} from "./cartSlice";
import type { CartState, CartStateDispatch } from "./cartSlice";
import {
  NewOrUpgradeProductProps,
  ProductType,
  ProductProps
} from "./cart-types";
import {
  CartNew,
  useCartPriceMutation,
  useCartPriceCheckQuery,
  useLicenseLookupMutation
} from "./piosolverStoreApi";
import type {
  LicenseLookupResult,
  PriceCalcResultError
} from "./piosolverStoreApi";
import {
  isActivationProduct,
  isExtensionProduct,
  isV2Product
} from "./products";

import styles from "./product.module.css";

function productForVersion(availableProducts: ProductType[], version?: string) {
  return availableProducts.find((p) => p.titleShort === version);
}

function isUpgradeOnlyVersion(
  availableProducts: ProductType[],
  version?: string
) {
  const product = productForVersion(availableProducts, version);
  if (!product) return false;
  return product.price < 0;
}

function ActiveOrderSummary({
  cart,
  state
}: Pick<ProductProps, "cart" | "state">) {
  const { getValues, watch } = useFormContext();
  watch(["existingLicense", "numberOfLicenses", "orderType", "version"]);
  const cartId = cart.cartId;
  const existingLicense = getValues("existingLicense") as string | undefined;
  const orderType = getValues("orderType") as CartState["orderType"];
  const version = getValues("version") as string;
  const numberOfLicenses = getValues("numberOfLicenses") as number;
  const skip =
    orderType === "offer" ||
    (orderType === "upgrade" && state.existingProduct !== existingLicense);
  const { data, error, isLoading, isFetching } = useCartPriceCheckQuery(
    {
      cartId,
      existingLicense,
      numberOfLicenses,
      orderType,
      version
    },
    { skip: skip, refetchOnMountOrArgChange: true }
  );
  if (orderType === "offer") return null;
  if (isLoading) return <Spinner />;
  if (error != null) return null;
  if (data == null) return null;
  // If we are skipping, then the data is not valid
  if (skip) return null;

  return (
    <div className={styles.summaryContainer}>
      <div
        className={clsx(
          styles.summaryLoadingOverlay,
          "mb-4",
          isFetching ? "d-block" : "d-none"
        )}>
        {isFetching && <CenteredSpinner />}
      </div>
      <div className={styles.summaryContent}>
        <OrderSummaryTable
          discount={0}
          fullUnitPrice={data.calculatedPrice.unitPrice ?? 0}
          message={data.calculatedPrice.message}
          numberOfLicenses={numberOfLicenses}
          productName={data.calculatedPrice.productName}
          // This is presented in the next page
          supportedUntil={undefined} // {data.calculatedPrice.supportedUntil}
          totalPrice={data.calculatedPrice.amount?.toFixed(2) ?? "0.00"}
          upgradeMessage={data.calculatedPrice.upgradeMessage}
        />
      </div>
    </div>
  );
}

type OrderType = Pick<CartNew, "availableProducts"> &
  Pick<CartState, "orderType">;
function NewOrUpgrade({ availableProducts, orderType }: OrderType) {
  const { getValues, register, setValue, watch } = useFormContext();
  watch("version");
  const version = getValues("version") as string;
  React.useEffect(() => {
    const isUpgradeOnly = isUpgradeOnlyVersion(availableProducts, version);
    if (orderType !== "upgrade" && isUpgradeOnly) {
      setValue("orderType", "upgrade");
    }
  }, [availableProducts, orderType, setValue, version]);

  return (
    <Form.Group className="mb-3">
      <div className="form-check form-check-inline">
        <input
          defaultChecked={orderType === "new"}
          disabled={isUpgradeOnlyVersion(availableProducts, version)}
          className="form-check-input"
          type="radio"
          id="licenseNew"
          onClick={() => {
            setValue("version", availableProducts[0].titleShort);
          }}
          value="new"
          {...register("orderType")}
        />
        <label className="form-check-label" htmlFor="licenseNew">
          New License
        </label>
      </div>
      <div className="form-check form-check-inline">
        <input
          defaultChecked={orderType === "upgrade"}
          className="form-check-input"
          type="radio"
          id="licenseUpgrade"
          value="upgrade"
          {...register("orderType")}
        />
        <label className="form-check-label" htmlFor="licenseUpgrade">
          Upgrade License
        </label>
      </div>
    </Form.Group>
  );
}

function getPriceString(product: ProductType) {
  if (product.price < 0) return "";
  return `($${product.price.toFixed(2)})`;
}

function ProductOption(product: ProductType) {
  return (
    <option value={product.titleShort}>
      {product.title} {getPriceString(product)}
    </option>
  );
}

type ChooseProductProps = Pick<CartNew, "availableProducts">;

function ChooseProduct({ availableProducts }: ChooseProductProps) {
  const { getValues } = useFormContext();

  const shownValue = getValues("version") as string;

  const component = (
    <>
      <Form.Group className="mb-3">
        <ProductSelection
          availableProducts={availableProducts}
          shownValue={shownValue}
        />
      </Form.Group>
    </>
  );
  return component;
}

function looksLikeLicenseKey(key: string) {
  const pattern = /^(\w{4}-){6}\w{4}$/;
  return pattern.test(key);
}

type ExistingLicenseProps = { cartId: string };
interface LicenseDetailsProps {
  data?: LicenseLookupResult;
  isError: boolean;
  isLoading: boolean;
}
function LicenseDetails({ data, isError, isLoading }: LicenseDetailsProps) {
  if (isLoading) {
    return (
      <div className="mt-2" style={{ height: "1.5rem" }}>
        <Spinner size={16} />
        Looking up license...
      </div>
    );
  }
  if (isError)
    return <div className="mt-2 text-danger">Could not verify license.</div>;

  if (!data) return null;

  if (data.error != null)
    return <div className="mt-2 text-danger">{data.error.message}</div>;

  if (data.found.expireDate != null) {
    return (
      <>
        <div className="mt-2">
          <span>
            Found license for <b>{data.found.version}</b>
            <br />
            for <i>{data.found.email}</i>, {data.found.activationCount}{" "}
            <i>
              {data.found.activationCount > 1 ? "activations" : "activation"}
            </i>
            {", "}
            updates till <i>{data.found.expireDate}</i>
          </span>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="mt-2">
        <span>
          Found license for <b>{data.found.version}</b>
          <br />
          for <i>{data.found.email}</i>, {data.found.activationCount}{" "}
          <i>{data.found.activationCount > 1 ? "activations" : "activation"}</i>
          {", "}
          created <i>{data.found.created.substring(0, 10)}</i>
        </span>
      </div>
    </>
  );
}

function ExistingLicense({ cartId }: ExistingLicenseProps) {
  const {
    formState: { errors },
    getValues,
    register,
    watch
  } = useFormContext();
  watch("orderType");
  watch("existingLicense");
  const userLicense = (getValues("existingLicense") ?? "") as string;
  const [data, setData] = React.useState<LicenseLookupResult | undefined>(
    undefined
  );

  const dispatch = useCartStateDispatch();
  const [lookupLicense, { error, isLoading }] = useLicenseLookupMutation();
  React.useEffect(() => {
    const licenseCandidate = userLicense;
    if (looksLikeLicenseKey(licenseCandidate)) {
      lookupLicense({ cartId, key: licenseCandidate })
        .then((result) => {
          if ("data" in result) {
            setData(result.data);
            if (result.data.found != null) {
              dispatch(
                setExistingProduct({
                  existingProduct: result.data.found.key,
                  existingLicenseActivationCount:
                    result.data.found.activationCount
                })
              );
            } else {
              dispatch(setExistingProduct({ existingProduct: undefined }));
            }
          }
        })
        .catch((e) => {
          dispatch(setExistingProduct({ existingProduct: undefined }));
        });
    } else {
      setData(undefined);
      dispatch(setExistingProduct({ existingProduct: undefined }));
    }
  }, [cartId, dispatch, lookupLicense, userLicense]);
  if (getValues("orderType") === "new") return null;
  return (
    <Form.Group className="mb-3">
      <Form.Label>License key</Form.Label>
      <input
        className={formControlCssClass(errors.existingLicense != null)}
        type="text"
        placeholder="Enter your license key"
        {...register("existingLicense", {
          required: true,
          minLength: 34,
          maxLength: 34,
          setValueAs: (value: string) => value.trim(),
          validate: (value: string) => {
            if (!looksLikeLicenseKey(value))
              return "Please enter a valid license key if you wish to upgrade.";
            if (data && data.error != null) return data.error.message;
            return true;
          }
        })}
      />
      {errors.existingLicense && (
        <ValidationError
          message="Please enter a valid license key if you wish to upgrade."
          messageDefault="Please enter a valid license key if you wish to upgrade."
        />
      )}
      <LicenseDetails
        data={data}
        isError={error != null}
        isLoading={isLoading}
      />
    </Form.Group>
  );
}

type ProductSelectionProps = ChooseProductProps & { shownValue?: string };
function ProductSelection({
  availableProducts,
  shownValue
}: ProductSelectionProps) {
  const { register } = useFormContext();

  const productOptions = availableProducts;
  return (
    <>
      <Form.Label>Product</Form.Label>
      <select
        aria-label="Product of PioSOLVER to purchase"
        id="version"
        className="form-select"
        defaultValue={shownValue}
        {...register("version")}>
        {productOptions.map((p, i) => (
          <ProductOption key={p.titleShort} {...p} />
        ))}
      </select>
    </>
  );
}

type NumberOfActivationsProps = Pick<
  CartState,
  "existingProduct" | "existingLicenseActivationCount" | "numberOfLicenses"
>;
function NumberOfActivations({
  existingProduct,
  existingLicenseActivationCount,
  numberOfLicenses
}: NumberOfActivationsProps) {
  const {
    formState: { errors },
    getValues,
    register,
    setValue,
    watch
  } = useFormContext();
  watch(["orderType", "version"]);
  const isUpgrade = getValues("orderType") === "upgrade";
  const version = getValues("version") as string;
  const isExtension = isExtensionProduct(version);

  React.useEffect(() => {
    if (isUpgrade || isExtension) {
      setValue("numberOfLicenses", existingLicenseActivationCount);
    } else {
      setValue("numberOfLicenses", 1);
    }
  }, [existingLicenseActivationCount, isExtension, isUpgrade, setValue]);

  if (isUpgrade && existingProduct == null) return null;
  // Can only buy 1 of a v2 product
  if (isV2Product(version)) return null;
  // Can ony extend all activations
  if (isExtension) return null;
  const isActivation = isActivationProduct(version);

  const maxNumberOfActivations =
    !isUpgrade || isActivation ? undefined : existingLicenseActivationCount;

  return (
    <Form.Group className="mb-3">
      <Form.Label>Number of Activations</Form.Label>
      <input
        className={formControlCssClass(errors.numberOfLicenses != null)}
        id="numberOfLicenses"
        min="1"
        max={maxNumberOfActivations}
        placeholder="1"
        type="number"
        defaultValue={numberOfLicenses > 0 ? numberOfLicenses : undefined}
        {...register("numberOfLicenses", {
          required: true,
          min: 1
        })}
      />
      {errors.numberOfLicenses == null &&
        (maxNumberOfActivations ? (
          <Form.Text>
            Enter the number of activations you wish to upgrade (up to{" "}
            {maxNumberOfActivations})
          </Form.Text>
        ) : (
          <Form.Text>
            Enter the number of activations you wish to purchase
          </Form.Text>
        ))}
      {errors.numberOfLicenses && (
        <span className="invalid-feedback">
          You should purchase at least one license.
        </span>
      )}
    </Form.Group>
  );
}

type ProductFormData = Pick<
  CartState,
  "orderType" | "numberOfLicenses" | "version"
>;

async function submitProductForm(
  cartId: string,
  data: ProductFormData,
  formMethods: UseFormReturn<
    Pick<ProductFormData, "orderType" | "numberOfLicenses" | "version"> & {
      existingLicense: string;
      existingProductFound: string;
    },
    unknown
  >,
  state: CartState,
  dispatch: CartStateDispatch,
  updatePrice: ReturnType<typeof useCartPriceMutation>[0]
) {
  // If the license is valid, we should have a product
  if (data.orderType === "upgrade" && state.existingProduct == null) {
    formMethods.setError(
      "existingLicense",
      {
        message: "Please enter a valid license key if you wish to upgrade."
      },
      { shouldFocus: true }
    );
    return;
  }
  try {
    const result = await updatePrice({
      cartId,
      orderType: data.orderType,
      numberOfLicenses: data.numberOfLicenses,
      version: data.version
    });

    if ("data" in result) {
      dispatch(
        setCalculatedPrice({
          calculatedPrice: result.data.calculatedPrice,
          orderType: data.orderType,
          version: data.version,
          numberOfLicenses: +data.numberOfLicenses
        })
      );
    }
  } catch (reason) {
    const calculatedPrice = {
      error: "Unknown" as PriceCalcResultError,
      message: "Could not update cart",
      productName: data.version
    };
    dispatch(
      setCalculatedPrice({
        calculatedPrice,
        orderType: data.orderType,
        version: data.version,
        numberOfLicenses: +data.numberOfLicenses
      })
    );
  }
}

function NewOrUpgradeProduct({
  availableProducts,
  cart,
  state
}: NewOrUpgradeProductProps) {
  const dispatch = useCartStateDispatch();
  const [updatePrice, { error: _error, isLoading: _isLoading }] =
    useCartPriceMutation();
  const cartId = cart.cartId;
  const formMethods = useForm({
    defaultValues: {
      orderType: state.orderType,
      version: state.version,
      numberOfLicenses: state.numberOfLicenses,
      existingLicense: state.existingLicense ?? "",
      existingProductFound: ""
    }
  });
  const {
    formState: { isSubmitting }
  } = formMethods;
  return (
    <FormProvider {...formMethods}>
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={formMethods.handleSubmit(async (data) => {
          await submitProductForm(
            cartId,
            data,
            formMethods,
            state,
            dispatch,
            updatePrice
          );
        })}>
        <ChooseProduct availableProducts={availableProducts} />
        <NewOrUpgrade
          availableProducts={availableProducts}
          orderType={state.orderType}
        />
        <ExistingLicense cartId={cartId} />
        <NumberOfActivations
          existingProduct={state.existingProduct}
          existingLicenseActivationCount={state.existingLicenseActivationCount}
          numberOfLicenses={state.numberOfLicenses}
        />
        <ActiveOrderSummary cart={cart} state={state} />

        <div className="d-flex flex-row-reverse">
          <button
            disabled={isSubmitting}
            className="btn btn-primary"
            type="submit">
            {isSubmitting && <Spinner size={16} />}
            Continue
          </button>
        </div>
      </form>
    </FormProvider>
  );
}

function CloudCreditsProductAlreadyPurchased({ cart, state }: ProductProps) {
  const amount = amountFromCloudCredits(state.version);
  return (
    <>
      <h3>Cloud Credits</h3>

      <div>
        <p>
          Your purchase of ${amount} credits has <b>already been processed</b>!
        </p>
        <p>
          Please go to the account page in PioCloud to see the updated balance.
        </p>
      </div>
    </>
  );
}

function CloudCreditsProduct({ cart, state }: ProductProps) {
  const dispatch = useCartStateDispatch();
  const [updatePrice, { error: _error, isLoading: _isLoading }] =
    useCartPriceMutation();
  const amount = amountFromCloudCredits(state.version);
  const cartId = cart.cartId;
  const formMethods = useForm({
    defaultValues: {
      orderType: state.orderType,
      version: state.version,
      numberOfLicenses: state.numberOfLicenses,
      existingLicense: state.existingLicense ?? "",
      existingProductFound: ""
    }
  });
  if (cart.orderId != null)
    return <CloudCreditsProductAlreadyPurchased {...{ cart, state }} />;
  const isSubmitting = formMethods.formState.isSubmitting;
  return (
    <FormProvider {...formMethods}>
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={formMethods.handleSubmit(
          async (data) =>
            await submitProductForm(
              cartId,
              data,
              formMethods,
              state,
              dispatch,
              updatePrice
            )
        )}>
        <h3>Cloud Credits</h3>

        <div>
          <p>
            You are about to purchase <b>${amount}</b> in credits for PioCloud.
          </p>
        </div>
        <div className="d-flex flex-row-reverse">
          <button
            disabled={isSubmitting}
            className="btn btn-primary"
            type="submit">
            {isSubmitting && <Spinner size={16} />}
            Continue
          </button>
        </div>
      </form>
    </FormProvider>
  );
}

function OfferProduct({
  cart,
  state
}: Omit<ProductProps, "availableProducts">) {
  const dispatch = useCartStateDispatch();
  const [updatePrice, { error: _error, isLoading: _isLoading }] =
    useCartPriceMutation();
  const cartId = cart.cartId;
  const amount = amountFromOffer(state.version);
  const formMethods = useForm({
    defaultValues: {
      orderType: state.orderType,
      version: state.version,
      numberOfLicenses: state.numberOfLicenses,
      existingLicense: state.existingLicense ?? "",
      existingProductFound: ""
    }
  });
  const isSubmitting = formMethods.formState.isSubmitting;
  return (
    <FormProvider {...formMethods}>
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={formMethods.handleSubmit(
          async (data) =>
            await submitProductForm(
              cartId,
              data,
              formMethods,
              state,
              dispatch,
              updatePrice
            )
        )}>
        <h3>Offer</h3>

        <div>
          <p>
            Thanks for reaching out to us. To take advantage of the agreed-upon
            offer, please provide your contact information and billing details.
          </p>
          <p>
            You will be charged <b>${amount}</b>.
          </p>
        </div>
        <div className="d-flex flex-row-reverse">
          <button
            disabled={isSubmitting}
            className="btn btn-primary"
            type="submit">
            {isSubmitting && <Spinner size={16} />}
            Continue
          </button>
        </div>
      </form>
    </FormProvider>
  );
}

function Product({ cart, state }: ProductProps) {
  if (state.orderType === "offer") return <OfferProduct {...{ cart, state }} />;

  if (state.orderType === "cloud-credits")
    return <CloudCreditsProduct {...{ cart, state }} />;

  const isActivation = isActivationProduct(state.version);

  const availableProducts = cart.availableProducts;
  const filteredProducts = isActivation
    ? availableProducts
    : availableProducts.filter((p) => !isActivationProduct(p.versionName));
  return (
    <NewOrUpgradeProduct
      {...{ availableProducts: filteredProducts, cart, state }}
    />
  );
}

// For testing
export default Product;
