import {
  AnyAction,
  PayloadAction,
  ThunkDispatch,
  createSlice
} from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { RootState } from "../../app/store";
import { ProductsMain, isActivationProduct } from "./products";
import type {
  CheckoutPrepare,
  CheckoutResult,
  PriceCalcResult
} from "./piosolverStoreApi";

type SubmitResult =
  | {
      data: CheckoutResult;
      error?: never;
    }
  | {
      data?: never;
      error: unknown;
    };

const STATUSES = [
  { step: 0, label: "initial" },
  { step: 1, label: "contact" },
  { step: 2, label: "payment" },
  { step: 3, label: "review" },
  { step: 4, label: "submitted" },
  { step: 5, label: "paid" },
  { step: 5, label: "failed" }
] as const;

type CartStatus = (typeof STATUSES)[number];

interface CartStateProduct {
  orderType: "cloud-credits" | "new" | "offer" | "upgrade";
  version: string;
  numberOfLicenses: number;
  existingLicense?: string;
  existingProduct?: string;
  existingLicenseActivationCount?: number;
}

interface CartStateContact {
  contactEmail?: string;
  contactName?: {
    givenName?: string;
    familyName: string;
    companyName?: string;
  };
  billingAddress?: {
    streetAddress: string;
    city: string;
    state?: string;
    postalCode: string;
    countryName: string;
  };
}

interface CartStateCheckout {
  checkout?: CheckoutPrepare | null;
  nonce?: string;
  deviceData?: string;
  calculatedPrice?: PriceCalcResult;
  submitResult?: SubmitResult;
}

export interface CartState
  extends CartStateProduct,
    CartStateContact,
    CartStateCheckout {
  status: CartStatus;
  deepestStep: number;
  isRequestingNonce: boolean;
  isShowingAdditionalPaymentOptions: boolean;
}

const initialState: CartState = {
  orderType: "new",
  existingLicense: undefined,
  existingProduct: undefined,
  existingLicenseActivationCount: undefined,
  isRequestingNonce: false,
  isShowingAdditionalPaymentOptions: false,
  numberOfLicenses: 1,
  status: { step: 0, label: "initial" },
  deepestStep: 0,
  version: ProductsMain[0].titleShort
};

function updateDeepestStep(state: CartState, newStatus: CartStatus) {
  if (newStatus.step > state.deepestStep) state.deepestStep = newStatus.step;
}

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setBackToPayments: (state) => {
      state.status = STATUSES[2];
      state.checkout = undefined;
      state.nonce = undefined;
      state.isShowingAdditionalPaymentOptions = true;
    },
    setContact: (
      state,
      action: PayloadAction<
        Pick<CartState, "billingAddress" | "contactEmail" | "contactName">
      >
    ) => {
      state.billingAddress = action.payload["billingAddress"];
      state.contactEmail = action.payload["contactEmail"];
      state.contactName = action.payload["contactName"];
      state.status = STATUSES[2];
      updateDeepestStep(state, STATUSES[2]);
    },
    setCalculatedPrice: (
      state,
      action: PayloadAction<
        Pick<
          CartState,
          "calculatedPrice" | "orderType" | "version" | "numberOfLicenses"
        >
      >
    ) => {
      state.calculatedPrice = action.payload["calculatedPrice"];
      state.orderType = action.payload["orderType"];
      state.version = action.payload["version"];
      switch (action.payload["orderType"]) {
        case "new":
          state.numberOfLicenses = action.payload["numberOfLicenses"];
          state.existingLicense = undefined;
          state.existingProduct = undefined;
          break;
        case "upgrade":
          state.numberOfLicenses = action.payload["numberOfLicenses"];
          state.existingLicense = state.existingProduct;
          break;
      }
      const newStatus = STATUSES[1];
      state.status = STATUSES[1];
      updateDeepestStep(state, newStatus);
    },
    setCheckout: (
      state,
      action: PayloadAction<Pick<CartState, "checkout">>
    ) => {
      state.checkout = action.payload["checkout"];
    },
    setExistingProduct: (
      state,
      action: PayloadAction<
        Pick<CartState, "existingLicenseActivationCount" | "existingProduct">
      >
    ) => {
      state.existingProduct = action.payload["existingProduct"];
      if (action.payload["existingProduct"] != null) {
        state.existingLicenseActivationCount =
          action.payload["existingLicenseActivationCount"];
        state.numberOfLicenses = 1;
      } else {
        state.existingLicenseActivationCount = undefined;
        state.numberOfLicenses = 1;
      }
    },
    setInitialProduct: (
      state,
      action: PayloadAction<Pick<CartState, "orderType" | "version">>
    ) => {
      state.orderType = action.payload["orderType"];
      state.version = action.payload["version"];
      state.numberOfLicenses = 1;
      state.status = STATUSES[0];
      state.deepestStep = 0;
    },
    /** Set the nonce and complete requesting */
    setNonceAndDeviceData: (
      state,
      action: PayloadAction<Pick<CartState, "nonce" | "deviceData">>
    ) => {
      state.nonce = action.payload["nonce"];
      state.deviceData = action.payload["deviceData"];
      state.isRequestingNonce = false;
      state.status = STATUSES[3];
      updateDeepestStep(state, STATUSES[3]);
    },
    setRequestingNonce: (
      state,
      action: PayloadAction<Pick<CartState, "isRequestingNonce">>
    ) => {
      state.isRequestingNonce = action.payload["isRequestingNonce"];
    },
    setShowingAdditionalPaymentOptions: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isShowingAdditionalPaymentOptions = action.payload;
    },
    setStatus: (state, action: PayloadAction<number>) => {
      const newStatus = STATUSES[action.payload];
      state.status = newStatus;
      // reset the requesting nonce if we go before step 2
      if (newStatus.step < 2) state.isRequestingNonce = false;
      updateDeepestStep(state, newStatus);
    },
    setStatusFailed: (state, action: PayloadAction<void>) => {
      // special action to handle a failure
      state.status = STATUSES[STATUSES.length - 1];
    },
    setSubmitResult: (
      state,
      action: PayloadAction<Required<Pick<CartState, "submitResult">>>
    ) => {
      state.submitResult = action.payload["submitResult"];
      if ("data" in action.payload["submitResult"]) {
        state.status = STATUSES[5];
        state.deepestStep = 5;
      } else state.status = STATUSES[6];
    }
  }
});

function cartToProductName(cart: CartState) {
  return cart.calculatedPrice?.productName;
}

function cartToTotalPrice(cart: CartState) {
  return cart.calculatedPrice?.amount?.toFixed(2) ?? "0.00";
}

function cartToUnitPrice(cart: CartState) {
  return cart.calculatedPrice?.unitPrice;
}

function getProductOptions() {
  return ProductsMain;
}

function productNameToSimpleName(productNameStr: string) {
  return productNameStr.split("_")[0];
}

type ProductVersionAndType = {
  version: string;
  orderType: CartState["orderType"];
};

type KnownProduct = (typeof ProductsMain)[number];
function versionAndTypeForProduct(
  product: KnownProduct
): ProductVersionAndType {
  const orderType = isActivationProduct(product.titleShort) ? "upgrade" : "new";
  return { version: product.titleShort, orderType };
}

function getProductVersionAndType(
  titleShort: string
): ProductVersionAndType | undefined {
  let mainFiltered = ProductsMain.filter((o) => o.titleShort === titleShort);
  if (mainFiltered.length > 0) return versionAndTypeForProduct(mainFiltered[0]);

  // Check if the simple name is being used
  mainFiltered = ProductsMain.filter(
    (o) => productNameToSimpleName(o.titleShort) === titleShort
  );
  if (mainFiltered.length > 0) return versionAndTypeForProduct(mainFiltered[0]);

  return undefined;
}

export {
  STATUSES,
  cartToProductName,
  cartToTotalPrice,
  cartToUnitPrice,
  getProductOptions,
  getProductVersionAndType
};

export const {
  setBackToPayments,
  setCalculatedPrice,
  setCheckout,
  setContact,
  setExistingProduct,
  setInitialProduct,
  setNonceAndDeviceData,
  setRequestingNonce,
  setShowingAdditionalPaymentOptions,
  setStatus,
  setStatusFailed,
  setSubmitResult
} = cartSlice.actions;

export type CartStateDispatch = Dispatch<AnyAction> &
  ThunkDispatch<RootState, null, AnyAction>;
export type { CartStatus };

export const useCartStateDispatch = () => useDispatch<CartStateDispatch>();
export const useCartStateSelector: TypedUseSelectorHook<RootState> =
  useSelector;

export default cartSlice.reducer;
