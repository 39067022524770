import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Cart from "./features/cart";
import {
  getProductVersionAndType,
  setInitialProduct,
  useCartStateDispatch,
  useCartStateSelector
} from "./features/cart/cartSlice";
import {
  CLOUD_CREDITS_PREFIX,
  cartIdFromCloudCredits
} from "./utils/cloudCredits";
import { amountFromOffer } from "./utils/offers";
import { ErrorBoundary } from "./utils/errors";

function urlVersionToProductName(version: string | null): string | null {
  if (!version) return null;

  switch (version.toLowerCase()) {
    case "pro":
      return "Pro_v3";
    case "edge":
      return "Edge_v3";
    case "extension":
      return "Extension_v3";
    default:
      return version;
  }
}

type InitParams = {
  cloudCredits?: string;
  offer?: string;
  versionAndType?: ReturnType<typeof getProductVersionAndType>;
};
function extractInitParams(pathname: string, search: string): InitParams {
  if (pathname.startsWith("/offer/")) {
    if (amountFromOffer(pathname) < 1) return {};
    return { offer: pathname };
  }

  if (pathname.startsWith(CLOUD_CREDITS_PREFIX)) {
    if (cartIdFromCloudCredits(pathname).length < 1) return {};
    return { cloudCredits: pathname };
  }

  const urlParams = new URLSearchParams(search);
  const version = pathname.startsWith("/products/")
    ? pathname.slice(10)
    : urlParams.get("name");

  const name = urlVersionToProductName(version);

  if (!name) return {};
  const versionAndType = getProductVersionAndType(name);
  return { versionAndType };
}

function computeInitialProduct({
  cloudCredits,
  offer,
  versionAndType
}: InitParams): ReturnType<typeof getProductVersionAndType> {
  if (cloudCredits) {
    return {
      orderType: "cloud-credits",
      version: cloudCredits
    };
  }
  if (offer) {
    return {
      orderType: "offer",
      version: offer
    };
  }
  return versionAndType;
}

function App() {
  const { status, deepestStep } = useCartStateSelector((state) => state.cart);
  const location = useLocation();
  const dispatch = useCartStateDispatch();

  useEffect(() => {
    if (deepestStep > 0) return;

    const { cloudCredits, offer, versionAndType } = extractInitParams(
      location.pathname,
      location.search
    );
    if (!cloudCredits && !offer && !versionAndType) return;

    const initialProduct = computeInitialProduct({
      cloudCredits,
      offer,
      versionAndType
    });
    if (initialProduct) dispatch(setInitialProduct(initialProduct));
  });

  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      if (deepestStep > 0 && deepestStep < 5) {
        e.returnValue = true;
        e.preventDefault();
        return;
      }
    };

    // The event listener causes problems with Braintree, so skip on that step
    if (status.step !== 2) {
      window.addEventListener("beforeunload", onBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", onBeforeUnload);
      };
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [deepestStep, status]);

  return (
    <div>
      <Container fluid="lg">
        <ErrorBoundary>
          <Row>
            <Col xs={12} xl={{ offset: 1, span: 10 }}>
              <Cart />
            </Col>
          </Row>
        </ErrorBoundary>
      </Container>
    </div>
  );
}

export default App;
