import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { CartState } from "./cartSlice";
import type { ProductType } from "./cart-types";

type CustomerContactInfo = {
  contactEmail?: string;
  contactName?: {
    givenName?: string;
    familyName: string;
    companyName?: string;
  };
  billingAddress?: {
    streetAddress: string;
    city: string;
    state?: string;
    postalCode: string;
    countryName: string;
  };
};

type CartNew = {
  cartId: string;
  customerContact?: CustomerContactInfo;
  orderId?: string;
  availableProducts: ProductType[];
  availableGateways: KnownGateway[];
  availablePaymentMethods: {
    applePay?: {
      enabled?: boolean;
    };
    googlePay?: {
      environment?: string;
      merchantId?: string;
      enabled?: boolean;
    };
    paypal?: {
      enabled?: boolean;
    };
  };
};

type CartCustomerContactParams = Pick<
  CartState,
  "billingAddress" | "contactEmail" | "contactName"
> &
  Pick<CartNew, "cartId">;
type CartCustomerContactResult = Record<string, string>;

type CartNewParams = {
  cartId?: string | null | undefined;
};

type CheckoutParams = Omit<
  CartState,
  | "deepestStep"
  | "status"
  | "isRequestingNonce"
  | "isShowingAdditionalPaymentOptions"
> &
  Pick<CartNew, "cartId">;

type CheckoutPrepareParams = Pick<CartNew, "cartId"> & {
  gateway: KnownGateway;
};

type CheckoutPrepare = {
  cartId: string;
  clientToken: string;
  gateway: KnownGateway;
  merchantId: string;
  success: boolean;
};

type CheckoutResult = {
  status: string;
  success: boolean;
  successDetails?: {
    amount: string;
    email: string;
    transactionId: string;
  };
  errorMessage?: string;
  error?: {
    message: string;
  };
};

type KnownGateway = "braintree" | "braintree-test" | "dummy" | "promo-code";

type LicenseDetailsParams = { cartId: string; key: string };
type LicenseLookupResult =
  | {
      error: { message: string };
      found: undefined;
    }
  | {
      error: undefined;
      found: {
        activationCount: number;
        created: string;
        email: string;
        key: string;
        version: string;
        expireDate?: string;
      };
    };

type PriceCalcResultError =
  | "BadRequest"
  | "ContactUs"
  | "CannotUpgrade"
  | "Unknown";
type PriceCalcParams = { cartId: string } & Pick<
  CartState,
  "orderType" | "numberOfLicenses" | "version"
>;
type PriceCalcResult = {
  amount?: number;
  supportedUntil?: string;
  error?: PriceCalcResultError;
  message?: string;
  productName: string;
  unitPrice?: number;
  upgradeMessage?: string;
};
type ProductPricingResult = {
  calculatedPrice: PriceCalcResult;
};

// Define a service using a base URL and expected endpoints
export const piosolverStoreApi = createApi({
  reducerPath: "piosolverStoreApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/api/store/" }),
  endpoints: (builder) => ({
    cartNew: builder.query<CartNew, CartNewParams>({
      query: (params) => {
        return {
          url: "cart/new",
          method: "POST",
          body: params
        };
      }
    }),
    cartCustomerContact: builder.mutation<
      CartCustomerContactResult,
      CartCustomerContactParams
    >({
      query: (params) => {
        return {
          url: "cart/contact",
          method: "PUT",
          body: params
        };
      }
    }),
    cartPrice: builder.mutation<ProductPricingResult, PriceCalcParams>({
      query: (params) => {
        return {
          url: `cart/pricing`,
          method: "PUT",
          body: params
        };
      }
    }),
    cartPriceCheck: builder.query<
      ProductPricingResult,
      PriceCalcParams & { existingLicense: string | undefined }
    >({
      query: (params) => {
        return {
          url: `cart/pricing`,
          method: "POST",
          body: params
        };
      }
    }),
    checkoutPrepare: builder.mutation<CheckoutPrepare, CheckoutPrepareParams>({
      query: (params) => {
        return {
          url: "checkout/prepare",
          method: "POST",
          body: params
        };
      }
    }),
    checkoutSubmit: builder.mutation<CheckoutResult, CheckoutParams>({
      query: (params) => {
        return {
          url: "checkout",
          method: "POST",
          body: params
        };
      }
    }),
    licenseLookup: builder.mutation<LicenseLookupResult, LicenseDetailsParams>({
      query: (params) => {
        return {
          url: `license/lookup/cart`,
          method: "POST",
          body: params
        };
      }
    })
  })
});

export const {
  useCartNewQuery,
  useCartCustomerContactMutation,
  useCartPriceMutation,
  useCartPriceCheckQuery,
  useLicenseLookupMutation,
  useCheckoutPrepareMutation,
  useCheckoutSubmitMutation
} = piosolverStoreApi;
export type {
  CartNew,
  CheckoutPrepare,
  CheckoutResult,
  KnownGateway,
  LicenseLookupResult,
  PriceCalcResult,
  PriceCalcResultError
};
