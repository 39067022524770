import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { ArrowLeft } from "react-bootstrap-icons";

import { cartIdFromCloudCredits } from "../../utils/cloudCredits";
import { CenteredSpinner } from "../../utils/spinner";
import { CartStateProps } from "./cart-types";
import {
  setBackToPayments,
  setStatusFailed,
  useCartStateDispatch,
  useCartStateSelector
} from "./cartSlice";
import type { CartStatus } from "./cartSlice";
import { useCartNewQuery } from "./piosolverStoreApi";
import type {
  CartNew,
  CheckoutPrepare,
  CheckoutResult
} from "./piosolverStoreApi";
import BackendDown from "./BackendDown";
import Contact from "./Contact";
import OrderSummary from "./OrderSummary";
import PayReviewProcess from "./Payment";
import Product from "./Product";
import { ProductsMain } from "./products";
import useStatusNavigate, { statusForLabel } from "./useStatusNavigate";

const minPrice = Math.min(
  ...ProductsMain.filter((p) => p.price > 0).map((p) => p.price)
);

function linkClass(step: number, current: number, status: CartStatus) {
  if (status.label === "failed") return "button";
  if (step > current) return "span";
  if (current > 3) return "span";
  return "button";
}

function linkProps(
  target: CartStatus["label"],
  current: number,
  statusNavigate: ReturnType<typeof useStatusNavigate>,
  currentStatus: CartStatus
) {
  const step = statusForLabel(target)?.step ?? 0;
  const statusFailed = currentStatus.label === "failed";
  if (!statusFailed) {
    if (step > current) return undefined;
    if (current > 3) return undefined;
  }
  return {
    className: "btn btn-link p-0 border-0 align-baseline",
    onClick: () => statusNavigate(target)
  };
}

function Header({ state }: CartStateProps) {
  const step = state.status.step;
  const statusNavigate = useStatusNavigate();
  const isCloudCreditPurchase = state.orderType === "cloud-credits";
  const fromPrice =
    state.status.step > 0 || isCloudCreditPurchase
      ? ""
      : `From $${minPrice.toFixed(0)}`;
  return (
    <div className="mb-4">
      <div className="d-flex justify-content-between align-items-baseline border-bottom my-2">
        <div className="d-flex align-items-baseline">
          <img
            alt="piosolver logo"
            className="me-2"
            src="/logo192.png"
            style={{ width: "1.5em" }}
          />
          <h3>
            {isCloudCreditPurchase
              ? "Purchase PioCloud Credits"
              : "Purchase PioSOLVER"}
          </h3>
        </div>
        <div>{fromPrice}</div>
      </div>
      <Breadcrumb className="fs-6">
        <Breadcrumb.Item
          active={step === 0}
          linkAs={linkClass(0, step, state.status)}
          linkProps={linkProps("initial", step, statusNavigate, state.status)}>
          Product
        </Breadcrumb.Item>
        <Breadcrumb.Item
          active={step === 1}
          linkAs={linkClass(1, step, state.status)}
          linkProps={linkProps("contact", step, statusNavigate, state.status)}>
          Contact
        </Breadcrumb.Item>
        <Breadcrumb.Item
          active={step === 2}
          linkAs={linkClass(2, step, state.status)}
          linkProps={linkProps("payment", step, statusNavigate, state.status)}>
          Payment
        </Breadcrumb.Item>
        <Breadcrumb.Item
          active={step === 3}
          linkAs={linkClass(3, step, state.status)}
          linkProps={linkProps("review", step, statusNavigate, state.status)}>
          Review
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
}

function ResultSuccessHeader({ state }: CartStateProps) {
  if (state.orderType === "offer" || state.orderType == "cloud-credits")
    return <h3>Thank you for your purchase!</h3>;
  return <h3>Thank you for purchasing PioSOLVER!</h3>;
}

function ResultSuccessDetails({ state }: CartStateProps) {
  if (state.orderType === "offer") {
    return (
      <div className="mt-4">
        <p>
          We will be reaching out to you shortly to follow up on your purchase.
        </p>
        <p className="text-secondary">
          All orders are processed manually, and it may take up to 1 business
          day for processing. (PioSOLVER is based in Europe, so please be
          patient if the order was issued during European night).
        </p>
      </div>
    );
  }
  if (state.orderType === "cloud-credits") {
    return (
      <div className="mt-4">
        <p>
          You can return to PioCloud and you should see the increased credit
          balance shortly.
        </p>
        <p className="text-secondary">
          If the credits do not appear after a few minutes, try the
          &ldquo;Refresh&rdquo; button or refresh the entire page.
        </p>
      </div>
    );
  }
  return (
    <div className="mt-4">
      <p>
        You should receive the PioSOLVER files and installation information
        soon, but please give us some time to process your order.
      </p>
      <p className="text-secondary">
        All orders are processed manually, and it may take up to 1 business day
        for processing. (PioSOLVER is based in Europe, so please be patient if
        the order was issued during European night).
      </p>
    </div>
  );
}
function BackToPaymentButton() {
  const dispatch = useCartStateDispatch();
  return (
    <button
      className="btn btn-secondary"
      onClick={() => {
        dispatch(setBackToPayments());
      }}>
      <ArrowLeft className="me-2" style={{ fontSize: "1.5rem" }} />
      <span>Back to payment</span>
    </button>
  );
}
type ResultSuccessProps = CartStateProps & {
  submitResult: CheckoutResult;
};

function ResultSuccess({ state, submitResult }: ResultSuccessProps) {
  const transactionId = submitResult.successDetails?.transactionId;
  const amount = submitResult.successDetails?.amount;
  const contactEmail = submitResult.successDetails?.email;
  return (
    <div>
      <ResultSuccessHeader state={state} />
      <div className="mb-4" style={{ maxWidth: "500px" }}>
        <Table size="sm">
          <tbody>
            <tr>
              <th scope="row">Transaction Id</th>
              <td>{transactionId}</td>
            </tr>
            <tr>
              <th scope="row">Email</th>
              <td>{contactEmail}</td>
            </tr>
            <tr>
              <th scope="row">Amount</th>
              <td>{amount}</td>
            </tr>
          </tbody>
        </Table>
        <ResultSuccessDetails state={state} />
        <div className="d-flex flex-row-reverse mt-5">
          <Button href="/">Back Home</Button>
        </div>
      </div>
    </div>
  );
}

type ResultFailureProps = {
  errorMessage?: string;
  gateway: CheckoutPrepare["gateway"] | undefined;
  status: string;
};
function ResultFailureCreditCard({ errorMessage, status }: ResultFailureProps) {
  let message = (
    <span>
      There was a problem processing the credit card. Please refresh the browser
      and try again with a different credit card.
    </span>
  );
  if (status === "error")
    message = <span>Please refresh the browser and try again.</span>;
  // see checkoutResultForBraintreeResult for these strings
  if (errorMessage === "three_d_secure")
    message = (
      <>
        <div>
          There was a problem with the <b>3D Secure authentication.</b> You can
          go back and try the credit card again, but you may need to contact the
          card issuer if this problem persists.
        </div>
        <div className="mt-2">
          Alternatively, you can try a different credit card.
        </div>
      </>
    );
  if (errorMessage === "too_many_attempts")
    message = (
      <>
        <div>
          If you have tried this credit card or email address multiple times in
          the last 30 minutes, you should try a different credit card/email or
          wait 30 minutes before trying again.
        </div>
        <div className="mt-2">
          If this problem persists, please contact <i>support@piosolver.com</i>.
        </div>
      </>
    );
  if (errorMessage === "call_issuer")
    message = (
      <>
        <div>
          The card issuer requests that you call them before this transaction
          can be completed.
        </div>
        <div className="mt-2">
          Alternatively, you can try a different credit card.
        </div>
      </>
    );
  return (
    <div>
      <h3>The sale did not go through</h3>
      <div className="mb-4" style={{ maxWidth: "500px" }}>
        {message}
      </div>
      <BackToPaymentButton />
    </div>
  );
}

function ResultFailurePurchaseCode({ status: _status }: ResultFailureProps) {
  const message = (
    <span>
      The provided purchase code was invalid. Please go back and try again with
      a different purchase code or a credit card.
    </span>
  );
  return (
    <div>
      <h3>The sale did not go through</h3>
      <div className="mb-4" style={{ maxWidth: "500px" }}>
        {message}
      </div>
      <BackToPaymentButton />
    </div>
  );
}

function ResultFailure({ gateway, status, errorMessage }: ResultFailureProps) {
  if (gateway === "promo-code")
    return <ResultFailurePurchaseCode gateway={gateway} status={status} />;
  return (
    <ResultFailureCreditCard
      errorMessage={errorMessage}
      gateway={gateway}
      status={status}
    />
  );
}

function hasErrorMessage(
  error: unknown
): error is { data: { message: string } } {
  if (error == null) return false;
  if ((error as { data: unknown }).data == null) return false;
  if ((error as { data: { message: string } }).data.message == null)
    return false;
  return true;
}

function Result({ state }: CartStateProps) {
  const dispatch = useCartStateDispatch();
  React.useEffect(() => {
    if (
      state.submitResult == null ||
      state.submitResult.data == null ||
      state.submitResult.data.success === false
    )
      dispatch(setStatusFailed());
  }, [state, dispatch]);

  if (state.submitResult == null)
    return <ResultFailure gateway={state.checkout?.gateway} status="error" />;
  if (state.submitResult.data == null) {
    const error = state.submitResult.error;
    const errorMessage = hasErrorMessage(error) ? error.data.message : null;
    return (
      <div>
        <h3>The sale did not go through; please refresh and try again</h3>
        {errorMessage && <p>{errorMessage}</p>}
        <BackToPaymentButton />
      </div>
    );
  }
  const isSuccess = state.submitResult.data.success === true;

  return (
    <div>
      {isSuccess ? (
        <ResultSuccess state={state} submitResult={state.submitResult.data} />
      ) : (
        <ResultFailure
          gateway={state.checkout?.gateway}
          status={state.submitResult.data.status}
          errorMessage={state.submitResult.data.errorMessage}
        />
      )}
    </div>
  );
}

type CartBodyProps = CartStateProps & { cart: CartNew };

function CartBody({ cart, state }: CartBodyProps) {
  if (cart == null) return null;

  switch (state.status.step) {
    case 0:
      return <Product cart={cart} state={state} />;
    case 1:
      return <Contact cart={cart} state={state} />;
    case 2:
    case 3:
    case 4:
      return <PayReviewProcess cart={cart} state={state} />;
    default:
      return <Result state={state} />;
  }
}

function LoadingSpinner() {
  return (
    <div className="vh-100 d-flex align-items-center">
      <div className="w-100">
        <CenteredSpinner />
      </div>
    </div>
  );
}

function CartMainContent({ cart, state }: CartBodyProps) {
  if (state.status.step > 4) return <Result state={state} />;
  return (
    <div className="d-flex flex-column-reverse flex-lg-row justify-content-between">
      <div className="flex-grow-1 p-4" style={{ maxWidth: "500px" }}>
        <CartBody cart={cart} state={state} />
      </div>
      <div className="ms-lg-1" style={{ maxWidth: "800px" }}>
        {state.status.step < 3 && <OrderSummary cart={cart} state={state} />}
      </div>
    </div>
  );
}

export default function Cart() {
  const state = useCartStateSelector((state) => state.cart);
  const cartId = cartIdFromCloudCredits(state.version);
  const { data, error, isLoading } = useCartNewQuery({ cartId });
  if (error != null) return <BackendDown />;
  if (isLoading || !data) return <LoadingSpinner />;
  if (data.cartId == null) return <BackendDown />;
  return (
    <div className="pb-4">
      <Header state={state} />
      <CartMainContent cart={data} state={state} />
    </div>
  );
}

// For testing
export { Result };
