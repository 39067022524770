import React from "react";
import Table from "react-bootstrap/Table";

import { CartStateProps } from "./cart-types";
import {
  cartToProductName,
  cartToTotalPrice,
  cartToUnitPrice
} from "./cartSlice";
import type { CartState } from "./cartSlice";
import type { CartNew } from "./piosolverStoreApi";

function cartStateToProduct(cart: CartNew, state: CartState) {
  if (state.orderType === "offer") {
    return {
      title: "Offer",
      titleShort: "Offer",
      price: state.calculatedPrice?.amount ?? 0
    };
  }
  if (state.orderType === "cloud-credits") {
    return {
      title: "Cloud Credits",
      titleShort: "Cloud Credits",
      price: state.calculatedPrice?.amount ?? 0
    };
  }
  const options = cart.availableProducts;
  return options.filter((o) => o.titleShort === state.version)[0];
}


function ContactSummary({ cart, state }: CartBodyProps) {
  const step = state.status.step;
  if (step < 1) return null;
  if (step > 3) return null;
  return (
    <div className="mb-4">
      <Table size="sm">
        <tbody>
          <tr>
            <th scope="row">Email</th>
            <td>{state.contactEmail ?? ""}</td>
          </tr>
          <tr>
            <th scope="row">Name</th>
            <td>
              {state.contactName?.givenName ?? ""}{" "}
              {state.contactName?.familyName ?? ""}
            </td>
          </tr>
          <tr>
            <th scope="row">Address</th>
            <td>
              <div>{state.billingAddress?.streetAddress ?? ""}</div>
              <div>
                {state.billingAddress?.city ?? ""}{" "}
                {state.billingAddress?.state ?? ""}{" "}
                {state.billingAddress?.postalCode ?? ""}
              </div>
              <div>{state.billingAddress?.countryName ?? ""}</div>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

type DiscountProps = {
  discount: number;
  numberOfLicenses: number;
};
function Discount({ discount }: DiscountProps) {
  if (discount < 1) return null;
  return (
    <tr className="text-danger">
      <td>Discount for license trade-in</td>
      <td></td>
      <td className="text-end">{(discount * -1).toFixed(2)}</td>
    </tr>
  );
}

type PricingMessageProps = {
  message: string | undefined;
};
function PricingMessage({ message }: PricingMessageProps) {
  if (message == null) return null;
  return (
    <div className="text-black-50" style={{ fontSize: "smaller" }}>
      {message}
    </div>
  );
}

function UpgradeMessage({ message }: PricingMessageProps) {
  if (message == null) return null;
  return (
    <div className="text-danger" style={{ fontSize: "smaller" }}>
      {message}
    </div>
  );
}

type TotalDisplayProps = {
  totalPrice: string;
};
function TotalDisplay({ totalPrice }: TotalDisplayProps) {
  return (
    <div className="d-flex justify-content-between">
      <div className="fw-bold">Total</div>
      <div className="fw-bold">{totalPrice}</div>
    </div>
  );
}

function computeFullUnitPrice(
  productPrice: number,
  unitPrice: number | undefined
) {
  const price = productPrice > 0 ? productPrice : unitPrice ?? 0;
  return price;
}

type CartBodyProps = CartStateProps & { cart: CartNew };
function OrderSummary({ cart, state }: CartBodyProps) {
  const step = state.status.step;
  if (step < 1) return null;
  if (step > 3) return null;
  const error = state.calculatedPrice?.error;
  if (error != null) return null;
  const product = cartStateToProduct(cart, state);
  const totalPrice = cartToTotalPrice(state);
  const unitPrice = cartToUnitPrice(state);
  const productName = cartToProductName(state) ?? product.title;
  const supportedUntil = state.calculatedPrice?.supportedUntil;
  const message = state.calculatedPrice?.message;
  const fullUnitPrice = computeFullUnitPrice(product.price, unitPrice);
  const discount =
    unitPrice != null && unitPrice < product.price
      ? (product.price - unitPrice) * (state.numberOfLicenses ?? 1)
      : 0;

  return (
    <OrderSummaryTable
      discount={discount}
      fullUnitPrice={fullUnitPrice}
      message={message}
      numberOfLicenses={state.numberOfLicenses ?? 1}
      productName={productName}
      supportedUntil={supportedUntil}
      totalPrice={totalPrice}
      upgradeMessage={state.calculatedPrice?.upgradeMessage}
    />
  );
}

type OrderSummaryTableProps = {
  discount: number;
  fullUnitPrice: number;
  message?: string;
  numberOfLicenses: number;
  productName: string;
  supportedUntil?: string;
  totalPrice: string;
  upgradeMessage?: string;
};
function OrderSummaryTable({
  discount,
  fullUnitPrice,
  message,
  numberOfLicenses,
  productName,
  supportedUntil,
  totalPrice,
  upgradeMessage
}: OrderSummaryTableProps) {
  return (
    <div className="mb-4 order-summary">
      <Table size="sm">
        <thead>
          <tr>
            <th scope="col">Product</th>
            <th scope="col">Quantity</th>
            <th scope="col">Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {productName}{" "}
              {supportedUntil && (
                <span style={{ fontSize: "smaller" }}>
                  <br />
                  Updates until {supportedUntil}
                </span>
              )}
            </td>
            <td className="text-center">{numberOfLicenses}x</td>
            <td className="text-end">{fullUnitPrice.toFixed(2)}</td>
          </tr>
          <Discount discount={discount} numberOfLicenses={numberOfLicenses} />
        </tbody>
      </Table>
      <TotalDisplay totalPrice={totalPrice} />
      <PricingMessage message={message} />
      <UpgradeMessage message={upgradeMessage} />
    </div>
  );
}

export default OrderSummary;
export { ContactSummary, OrderSummaryTable };
